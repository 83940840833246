import { render, staticRenderFns } from "./ProArtists.vue?vue&type=template&id=4abaa572&scoped=true&"
import script from "./ProArtists.vue?vue&type=script&lang=ts&"
export * from "./ProArtists.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4abaa572",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VContainer,VDivider,VItemGroup,VOverlay,VPagination,VProgressCircular})
